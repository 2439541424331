import moment from "moment";
import { BASE_URL } from "../../../global";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { toast } from "react-toastify";

const submitRanges = async (insertedID, readingRows, params, props) => {
  const valueAndUnit = [
    { field: "paratype", unit: "paratype_unit" },
    { field: "uncertainty", unit: "uncertainty_unit" },
    { field: "accuracy", unit: "accuracy_unit" },
    { field: "percentOfOutput", unit: "percentOfOutput_unit" },
    { field: "plusValue", unit: "plusValue_unit" },
    { field: "stability", unit: "stability_unit" },
    { field: "uniformity", unit: "uniformity_unit" },
    { field: "axialUniformity", unit: "axialUniformity_unit" },
    { field: "radialUniformity", unit: "radialUniformity_unit" },
    { field: "reactionTime", unit: "reactionTime_unit" },
    { field: "gravity", unit: "gravity_unit" },
    { field: "drift", unit: "drift_unit" },
    { field: "mpe", unit: "mpe_unit" },
    { field: "materialDensity", unit: "materialDensity_unit" },
    { field: "cmv", unit: "cmv_unit" },
    { field: "deviation", unit: "deviation_unit" },
    { field: "emissivity", unit: "emissivity_unit" },
    { field: "remarks", unit: "remarks_unit" },
  ];

  const formatValueWithUnit = (value, unit) =>
    unit ? `${value}#${unit}` : value;

  const processRow = (row) => {
    const rangeName = `${formatValueWithUnit(
      row.from_range,
      row.from_range_unit
    )}|${formatValueWithUnit(row.to_range, row.to_range_unit)}`;
    return {
      rangeName,
      standardId: Number(insertedID),
      parameter: row.parameter,
      mode: row.mode,
      etParameter: row.etParameter,
      id: row.id,
      ...valueAndUnit.reduce((acc, { field, unit }) => {
        acc[field] = formatValueWithUnit(row[field], row[unit]);
        return acc;
      }, {}),
    };
  };


  const hasChanged = (oldRow, newRow) => {
    const filteredOldRow = Object.fromEntries(
      Object.entries(oldRow).filter(([key, value]) => {
        if (value === null || value === undefined || value === "") {
          return false;
        }
        if (typeof value === "string" && value.trim() === "") {
          return false;
        }
        return true;
      })
    );
    const filteredNewRow = Object.fromEntries(
      Object.entries(newRow).filter(([key, value]) => {
        if (value === null || value === undefined || value === "") {
          return false;
        }
        if (typeof value === "string" && value.trim() === "") {
          return false;
        }
        return true;
      })
    );
    return Object.keys(filteredOldRow).some((key) => {
      if (filteredOldRow[key] !== filteredNewRow[key]) {
        return true;
      }
    }) || Object.keys(filteredNewRow).some((key) => {
      if (!(key in filteredOldRow)) {
        return true;
      }
    });
  };

  try {
    const rows = readingRows?.map(processRow)?.map(row => 
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, value !== "" && value !== undefined ? value : null])
      )
    );

    const bulkUrl = BASE_URL + `standardRanges/bulk`;

    if (params.id == 0) {
      await axiosWithToken.post(bulkUrl, rows);
      toast.success("Standard created successfully!");
    } else {
      const parsedData =
        JSON.parse(sessionStorage.getItem("masterEQPstandardRanges")) || [];
      const oldRows = parsedData.map((row) => {
        const { lastModified, status, ...rest } = row;
        return rest;
      });

      const rowsToUpdate = rows.filter((row) => row.id);
      const rowsToInsert = rows.filter((row) => !row.id);

      const rowsToPatch = rowsToUpdate.filter((newRow) => {
        const oldRow = oldRows.find((row) => row.id === newRow.id);
        return oldRow && hasChanged(oldRow, newRow);
      });

      // console.log('Rows to Patch:', rowsToPatch);
      // console.log('Rows to Insert:', rowsToInsert);

      const updatePromises = rowsToPatch.map((row) =>
        axiosWithToken.patch(BASE_URL + `standardRanges/${row.id}`, row)
      );
      const insertPromises =
        rowsToInsert.length > 0
          ? [axiosWithToken.post(bulkUrl, rowsToInsert)]
          : [];

      await Promise.all([...updatePromises, ...insertPromises]);

      toast.success("Standards updated successfully!");
    }
  } catch (err) {
    console.error(err);
    props.setLoader(false);
  } finally {
    props.setLoader(false);
  }
};

const updateData = (props, standardDetails, srfObject, readingRows, params) => {
  let row = {
    standardName: standardDetails?.standardName,
    stId: standardDetails?.ids,
    serialNo: standardDetails?.serialNo,
    make: standardDetails?.make,
    model: standardDetails?.model,
    certificateNumber: standardDetails?.certificateNumber,
    traceability: standardDetails?.traceability,
    calDate: moment(standardDetails?.calDate).format("YYYY-MM-DD"),
    validUpto: standardDetails?.validUpto
      ? moment(standardDetails?.validUpto).format("YYYY-MM-DD")
      : null,
    certificateUrl: standardDetails?.certificateUrl
      ? standardDetails?.certificateUrl
      : null,
    disciplineId: srfObject.disciplineId,
    masterrange: srfObject.masterrange,
    masteraccuracy: srfObject.masteraccuracy,
    masterleastcount: srfObject.masterleastcount,
    masterUncertainty: srfObject.masterUncertainty,
    type: standardDetails?.EQMType,
    materialType:
      standardDetails?.materialType === "" ? "" : standardDetails?.materialType,
    location: standardDetails?.location,
  };

  if (params.id == 0) {
    axiosWithToken
      .post(BASE_URL + "standards", row)
      .then((res) => {
        var insertedID = res.data.insertId;
        submitRanges(insertedID, readingRows, params, props);
        // props.setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        // props.setLoader(false);
      });
  } else {
    axiosWithToken
      .patch(BASE_URL + `standards/${params.id}`, row)
      .then((res) => {
        submitRanges(params.id, readingRows, params, props);
        // props.setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        // props.setLoader(false);
      });
  }
};

export const handleSubmit = (
  props,
  standardDetails,
  srfObject,
  readingRows,
  params
) => {
  if (params.id == 0) {
    if (!srfObject.disciplineId) {
      toast.error("Please select a Discipline");
      return;
    }
    if (!standardDetails.standardName) {
      toast.error("Standard Name is required");
      return;
    }
    if (!(standardDetails.ids || standardDetails.serialNo)) {
      toast.error("ID or Serial Number is required");
      return;
    }
    if (!standardDetails.calDate) {
      toast.error("Cal Date is required");
      return;
    }
    if (!standardDetails.validUpto) {
      toast.error("Due Date is required");
      return;
    }
  }
  props.setLoader(true);
  updateData(props, standardDetails, srfObject, readingRows, params);
};
